<template>
  <TermsLayout>
    <div class="flex-wrap">
      <ContentsTable :items="items" :textRef="$refs.text" />
      <div>
        <TermsTitle :title="title" :subtitle="subtitle" />
        <div v-html="text" ref="text"></div>
      </div>
    </div>
  </TermsLayout>
</template>

<script>
import { TermsAPI } from "@/api/terms";

import ContentsTable from "./ContentsTable";
import TermsLayout from "./TermsLayout";
import TermsTitle from "./TermsTitle";
export default {
  name: "Consent",
  components: {
    TermsLayout,
    ContentsTable,
    TermsTitle,
  },
  data() {
    return {
      text: "",
      items: [],
      title: "Consent for treatment",
      subtitle: "Last Updated: Oct 01, 2020",
    };
  },
  async mounted() {
    this.text = await TermsAPI.fetchConsent();
    const regex = /<h2>(.*?)<\/h2>/g;
    let match;

    while ((match = regex.exec(this.text))) {
      this.items.push(match[1]);
    }
  },
};
</script>

<style lang="scss" scoped>
.flex-wrap {
  display: flex;
  flex-wrap: nowrap !important;
}
</style>
